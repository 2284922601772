<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A system that is not at equilibrium is found to contain
        <number-value :value="concA" unit="\text{M}" />
        of
        <chemical-latex content="A(aq)," />
        <number-value :value="concA" unit="\text{M}" />
        of
        <chemical-latex content="B(aq)," />
        and
        <number-value :value="concC" unit="\text{M}" />
        of
        <stemble-latex content="$\text{C(aq).}$" />
        Note that each of these species are hypothetical and do not represent actual elements from
        the periodic table. The reaction involving these three species is shown below:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="A(aq) + B(aq) <=> C(aq)" />
        <stemble-latex content="$\qquad\text{K}_\text{c}=$" />
        <number-value :value="Kc" />
      </p>

      <p class="mb-2">
        a) What is the value of
        <stemble-latex content="$\text{Q}_{\text{c}}$" />
        for the system based on the conditions described above?
      </p>

      <calculation-input
        v-model="inputs.Qvalue"
        class="mb-5"
        prepend-text="$\text{Q}_\text{c}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        b) Which direction would the reaction proceed in order to reach equilibrium?
      </p>

      <v-radio-group
        v-model="inputs.directionToEq"
        class="mb-3 pl-8 mt-1"
        dense
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="`$\\text{${option.expression}}$`" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        c) Determine the concentration of each of the three species once the system reaches
        equilibrium.
      </p>

      <calculation-input
        v-model="inputs.Aeq"
        class="mb-2"
        prepend-text="$\text{[A]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.Beq"
        class="mb-2"
        prepend-text="$\text{[B]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.Ceq"
        prepend-text="$\text{[C]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question173',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Qvalue: null,
        directionToEq: null,
        Aeq: null,
        Beq: null,
        Ceq: null,
      },
      options: [
        {expression: 'Shift left to form more reactants', value: '1'},
        {expression: 'Shift right to form more products', value: '2'},
        {expression: 'The system is already at equilibrium', value: '3'},
      ],
    };
  },
  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    concC() {
      return this.taskState.getValueBySymbol('concC').content;
    },
    Kc() {
      return this.taskState.getValueBySymbol('Kc').content;
    },
  },
};
</script>
